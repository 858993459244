import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// Libs
import TooltipComponent from 'components/Modal/Tooltip.js';
// Components
import {
  DividerStyled,
  PlansTabStyled,
  SearchDiv,
  SectionTitle,
  PlanItem,
  TargetList,
  ItemContent,
  CompanyItem,
  Span,
  SearchButton
} from './styled';
import {Checkbox} from '@material-ui/core';
import {Column, Row} from 'components/Flex';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Collapsible from 'components/Collapsible';
import TargetInfo from '../TargetInfo';
// Icons
import {MdExpandMore} from 'react-icons/md';
// Hooks
import {useTarget} from 'Context/TargetContext';
import {useMapContext} from 'Context/MapContext';
import useToggle from 'hooks/useToggle';
import {useTargetState} from 'Context/TargetStateContext';
import {useClientTargetsState} from 'Context/ClientTargetStateContext';
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import '../Target-tab.css';
import {useTargetCustomAlertState} from 'Context/TargetCustomAlertStateContext';
import { getRainAlertList } from 'services/mapsPortal/initialLoad';
import useInterval from 'hooks/useInterval';

const OptionsTooltip = (props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <TooltipComponent
      enterDelay={1000}
      leaveDelay={0}
      onClose={() => handleTooltipClose()}
      onOpen={() => handleTooltipOpen()}
      open={open}
      position="top-end"
      text={props.title}
    >
      {props.children}
      </TooltipComponent>
    )
}

const PlansTab = (props) => {
  const { toggleHotkeys} =
      useMapContext();
  const planRef = useRef(null);
  const { setPlanTabWidth } = props;
  const { clientTargets, toShow: checkboxes } = useClientTargetsState();
  const { targetState } = useTargetState();
  const [ searchValue, setSearchValue ] = useState('');
  const [ sortAscending, setSortAscending ] = useState(true);
  const [collapse, setCollapse] = useState([]);
  const [collapseAlerts, setCollapseAlerts] = useState([]);
  const [collapseRainAlert, setCollapseRainAlert] = useState([]);
  const [showCompanies, toggleShowCompanies] = useToggle(false);
  const [showInAlert, toggleShowInAlert] = useToggle(false);
  const [showCustomInAlert, toggleShowCustomInAlert] = useToggle(false);
  const { targetCustomAlertState } = useTargetCustomAlertState();
  const [showInRainAlert, toggleShowInRainAlert] = useToggle(false);
  const [inRainAlert, setInRainAlert] = useState([]);
  const [organizedShowInterface, setOrganizedShowInterface] = useState([]);
  const [isRain, setIsRain] = useState(false);

  const organizedInterface = [];

  useEffect(() => {
    const p = () => {
      const data = [{
        value: false,
        plans: clientTargets[0]?.planos.map((plano) => false)
      }]

      return data;
    }

    setCollapse(p())
    setCollapseAlerts(p())
  }, [JSON.stringify(clientTargets)])

  const collapseAlertCompany = (id) => {
    setCollapseAlerts(p => {
      const a = [...p];
      a[id].value = !a[id].value;
      return a;
    })
  }

  const collapseAlertPlan = (cId, pId) => {
    setCollapseAlerts(p => {
      const a = [...p];
      a[cId].plans[pId] = !a[cId].plans[pId]
      return a;
    })
  }


  const collapseCompany = (id) => {
    setCollapse(p => {
      const a = [...p];
      a[id].value = !a[id].value;
      return a;
    })
  }

  const collapsePlan = (cId, pId) => {
    setCollapse(p => {
      const a = [...p];
      a[cId].plans[pId] = !a[cId].plans[pId]
      return a;
    })
  }

  const handleFilter = (text) => {
    return text
      .toLowerCase()
      .includes(searchValue.toLowerCase())
  }
  
  const plansLinesToShow = clientTargets.filter(empresa => {
    const a = empresa.planos.filter(plan => {
        const targets = plan.alvos.filter(target => handleFilter(target.nome))
        return targets.length || handleFilter(plan.nome)
    })
    return a.length || handleFilter(empresa.nome)
  });
  
  const filterPlanInAlert = (plan) => {
    return plan.alvos.some(target => targetState[target.id_alvo]);
  }

  const filterCompanyInAlert = (company) => {
    return company.planos.some(plan => filterPlanInAlert(plan))
  }

  const filterPlanInAlertCustomAlert = (plan) => {
    return plan.alvos.some(target => targetCustomAlertState[target.id_alvo]);
  }

  const filterCompanyInCustomAlert = (company) => {
    return company.planos.some(plan => filterPlanInAlertCustomAlert(plan))
  }

  const filterinRainAlert = (plan) => {
    
    return plan.planos.some((subPlan) => {
      return subPlan.alvos.some((target) => {
        const targetMatch = inRainAlert && inRainAlert.some(alert => {
          return alert.id_alvo_id === target.id_alvo;
        });
        return targetMatch;
      });
    });
  };


  useEffect(() => {
    if(planRef){
      setPlanTabWidth(planRef?.current? planRef.current.getBoundingClientRect().width : null);
    }
  }, [planRef])

  const getRain = async () => {
    
    try {
      const respRain = await getRainAlertList();
      
      setInRainAlert(respRain);

    } catch (error) {
      console.log('Erro ao buscar alerta de chuva: ', error);
    }
  };


  useEffect(() => {
    getRain();
  }, []);

  useInterval(() => {
    getRain();
  }, 15000);
  


  return (
      <PlansTabStyled ref={planRef}>
          <SearchDiv>
            <Input
              style={{
                border: '1px solid #032A4A',
                paddingLeft: '6px',
                backgroundColor: 'white',
                flex: 1
              }}
              id="searchTargets"
              startAdornment={(
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              )}
              placeholder='Buscar'
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onFocus={() => toggleHotkeys(false)}
              onBlur={() => toggleHotkeys(true)}
            />
             <OptionsTooltip title={!sortAscending? "Organizar A-Z" : "Organizar Z-A"}>
              <SearchButton onClick={()=> setSortAscending(p => !p)}>
                {!sortAscending?
                  <FaArrowDown style={{fontSize: "16px"}}/> :
                  <FaArrowUp style={{fontSize: "16px"}}/>
                }
              </SearchButton>
            </OptionsTooltip>
          </SearchDiv>
        <DividerStyled />
        <TargetList>
          {!plansLinesToShow.length
            ? <SectionTitle>Nenhum Plano ou Alvo encontrado.</SectionTitle>
            : (
            <>


              {/* INTERFACE COM TODOS OS ALVOS DO USUARIO*/}
              <Collapsible isOpen={showCompanies} main={(
                      <>
                        <CompanyItem isOpen={showCompanies}>
                          <Checkbox
                            label={'Todos'}
                            onChange={checkboxes.toggleAll}
                            checked={checkboxes.getAll()}
                            style={{color: "#1B1B1B"}}
                          />
                          <ItemContent onClick={toggleShowCompanies}>
                            <h2>TODOS</h2>
                            <MdExpandMore className='itens-title-icon'/>
                          </ItemContent>
                        </CompanyItem>
                        <DividerStyled />
                      </>
                  )}>
                  {plansLinesToShow.sort((a, b) => sortAscending
                  ? a.nome.localeCompare(b.nome)
                  : b.nome.localeCompare(a.nome))
                .map((company) => 
                    {return company.planos
                      .filter(plan => {
                          const targets = plan.alvos.filter(target => handleFilter(target.nome))
                          return targets.length || handleFilter(plan.nome)
                      })
                      .map((plan) => 
                          {return plan.alvos
                            ?.filter(
                              target => handleFilter(target.nome) || handleFilter(plan.nome)
                            )
                            .sort((a, b) => sortAscending
                              ? a.nome.localeCompare(b.nome)
                              : b.nome.localeCompare(a.nome))
                            .map((target) => {
                              return(
                                <Column key={`sidebarAlvo-${plan.id}-${target.id}`}>
                                <TargetInfo
                                  target={target}
                                  plan={plan}
                                  company={company}
                                  checkboxes={checkboxes}
                                  alerts={true}
                                  checked={targetState[target.id_alvo]} //Need Change
                                  interfaceTimer={'company'}
                                  alertType='InCompany'
                                /> 
                                </Column>
                          )})}
                      )}

                  )}
              </Collapsible>


              {/* INTERFACE COM OS ALVOS DO USUARIO EM ALERTA */}
              <Collapsible isOpen={showInAlert} main={(
                  <>
                    <CompanyItem isOpen={showInAlert}>
                      <Checkbox
                        label={'Todos'}
                        onChange={checkboxes.toggleAll}
                        checked={checkboxes.getAll()}
                        style={{color: "#1B1B1B"}}
                      />
                      <ItemContent onClick={toggleShowInAlert}>
                        <h2>EM ALERTA</h2>
                        <MdExpandMore style={{fontSize: '32px'}}/>
                      </ItemContent>
                    </CompanyItem>
                    <DividerStyled />
                  </>
              )}>
                {plansLinesToShow.sort((a, b) => sortAscending
                  ? a.nome.localeCompare(b.nome)
                  : b.nome.localeCompare(a.nome))
                .filter((company) => filterCompanyInAlert(company))
                .map((company) => 
                      {return company.planos
                        .filter(plan => filterPlanInAlert(plan))
                        .filter(plan => {
                            const targets = plan.alvos.filter(target => handleFilter(target.nome))
                            return targets.length || handleFilter(plan.nome)
                        })
                        .map((plan) => 
                            {return plan.alvos
                              ?.filter(
                                target => targetState[target.id_alvo] && (handleFilter(target.nome) || handleFilter(plan.nome))
                              )
                              .sort((a, b) => sortAscending
                                ? a.nome.localeCompare(b.nome)
                                : b.nome.localeCompare(a.nome))
                              .map((target) => {
                                return(
                                  <Column key={`sidebarAlvoAlerta-${plan.id}-${target.id}`}>
                                    <TargetInfo
                                      target={target}
                                      plan={plan}
                                      company={company}
                                      checkboxes={checkboxes}
                                      checked={targetState[target.id_alvo]} //Need Change
                                      alerts={true}
                                      alertType="InAlert"
                                    />
                                  </Column>
                              )})})})}
              </Collapsible>

              
              {/* INTERFACE COM OS ALERTAS PERSONALIZADOS */}
              <Collapsible isOpen={showCustomInAlert} main={(
                  <>
                    <CompanyItem isOpen={showCustomInAlert}>
                      <Checkbox
                        label={'Todos'}
                        onChange={checkboxes.toggleAll}
                        checked={checkboxes.getAll()}
                        style={{color: "#1B1B1B"}}
                      />
                      <ItemContent onClick={toggleShowCustomInAlert}>
                        <h2>EM ALERTA PERSONALIZADO</h2>
                        <MdExpandMore style={{fontSize: '32px'}}/>
                      </ItemContent>
                    </CompanyItem>
                    <DividerStyled />
                  </>
                )}>
                {plansLinesToShow.sort((a, b) => sortAscending
                  ? a.nome.localeCompare(b.nome)
                  : b.nome.localeCompare(a.nome))
                .filter((company) => filterCompanyInCustomAlert(company))
                .map((company) => {
                  return(
                    <Collapsible
                      key={`sidebarempresaAlert-${company.id}`}
                      isOpen={collapseAlerts.length ? collapseAlerts[company.id].value : false}
                      main={(
                        <CompanyItem
                          key={company.id + '-sidebarEmpresa-alert'}
                          // isOpen={true}
                          checked={true} //ALTERAR LINHA COM STATUS DO ALVO: EM ALERTA = TRUE
                          isOpen={collapseAlerts.length ? collapseAlerts[company.id].value : false}
                        >
                          <Checkbox
                            label={company.nome}
                            onChange={() => checkboxes.toggle(company.id)}
                            checked={checkboxes.get(company.id)}
                            style={{color: "#1B1B1B"}}
                          />
                          <ItemContent onClick={() => collapseAlertCompany(company.id)}>
                            <Span>
                                {company.nome}
                            </Span>
                            <MdExpandMore style={{fontSize: '32px'}}/>
                          </ItemContent>
                        </CompanyItem>
                      )}
                    >
                      {company.planos
                        .filter(plan => filterPlanInAlertCustomAlert(plan))
                        .filter(plan => {
                            const targets = plan.alvos.filter(target => handleFilter(target.nome))
                            return targets.length || handleFilter(plan.nome)
                        })
                        .map((plan) => (
                          <Collapsible
                            key={`sidebarplanoAlert-${plan.id}`}
                            // isOpen={true}
                            isOpen={collapseAlerts.length ?collapseAlerts[company.id].plans[plan.id] : false}
                            main={(
                              <PlanItem
                                ml='16px'
                                key={plan.id + '-sidebarPlanoAlert-check'}
                                checked={true}
                                // isOpen={true}
                                isOpen={collapseAlerts.length ? collapseAlerts[company.id].plans[plan.id] : false}
                              >
                                <Checkbox
                                  label={plan.nome}
                                  onChange={() => checkboxes.toggle(company.id, plan.id)}
                                  checked={checkboxes.get(company.id, plan.id)}
                                  style={{color: "#1B1B1B"}}
                                />
                                <ItemContent onClick={() => collapseAlertPlan(company.id, plan.id)}>
                                  <Span ml='16px'>
                                      {plan.nome}
                                  </Span>
                                  <MdExpandMore style={{fontSize: '32px'}}/>
                                </ItemContent>
                              </PlanItem>
                            )}
                          >
                            {plan.alvos
                              ?.filter(
                                target => targetCustomAlertState[target.id_alvo] && (handleFilter(target.nome) || handleFilter(plan.nome)) 
                                  // setExistsPerso(true);
                                )
                              .sort((a, b) => sortAscending
                                ? a.nome.localeCompare(b.nome)
                                : b.nome.localeCompare(a.nome))
                              .map((target) => (
                              <Column key={`sidebarAlvoAlert-${plan.id}-${target.id}`}>
                                <TargetInfo
                                  target={target}
                                  plan={plan}
                                  company={company}
                                  checkboxes={checkboxes}
                                  checked={true} //Need Change
                                  alertType="InCustomAlert"
                                />
                              </Column>
                            ))}
                          </Collapsible>
                        ))}
                    </Collapsible>
                )})}
              </Collapsible>

              {/*  INTERFACE COM OS AVISOS DE CHUVA */}
              <Collapsible isOpen={showInRainAlert} main={(
                <>
                    <CompanyItem isOpen={showInRainAlert}>
                      <Checkbox
                        label={'Todos'}
                        onChange={checkboxes.toggleAll}
                        checked={checkboxes.getAll()} 
                        style={{color: "#1B1B1B"}}
                      />
                      <ItemContent onClick={toggleShowInRainAlert}>
                        <h2>EM AVISO DE CHUVA</h2>
                        <MdExpandMore style={{fontSize: '32px'}}/>
                      </ItemContent>
                    </CompanyItem>
                    <DividerStyled />
                  </>  
                )}>
                { plansLinesToShow.sort((a, b) => sortAscending
                ? a.nome.localeCompare(b.nome)
                : b.nome.localeCompare(a.nome)).filter((company) => filterinRainAlert(company)).map((company)=>{
                  return(
                    <Collapsible
                      key={`sidebarempresaAlert-${company.id}`}
                      isOpen={collapseAlerts.length ? collapseAlerts[company.id].value: false}
                      main={(
                        <CompanyItem
                          key={company.id + '-sidebarEmpresa-alert'}
                          checked={true}
                          isOpen={collapseAlerts.length ? collapseAlerts[company.id].value : false}
                        >
                          <Checkbox
                            label={company.nome}
                            onChange={() => checkboxes.toggle(company.id)}
                            checked={checkboxes.get(company.id)}
                            style={{color: "#1B1B1B"}}
                          />
                          <ItemContent onClick={() => collapseAlertCompany(company.id)}>
                            <Span>{company.nome}</Span>
                            <MdExpandMore style={{fontSize: '32px'}}/>
                          </ItemContent>
                        </CompanyItem>
                      )}
                    >
                      {company.planos
                      .filter(plan => {
                          const targets = plan.alvos.filter(target => handleFilter(target.nome))
                          return targets.length || handleFilter(plan.nome)
                        })
                        .map((plan) => (
                          <Collapsible
                            key={`sidebarplanoAlert-${plan.id}`}
                            isOpen={collapseAlerts.length ? collapseAlerts[company.id].plans[plan.id] : false}
                            main={(
                              <PlanItem
                                ml='16px'
                                key={plan.id + '-sidebarPlanoAlert-check'}
                                checked={true}
                                isOpen={collapseAlerts.length ? collapseAlerts[company.id].plans[plan.id] : false}
                              >
                                <Checkbox
                                  label={plan.nome}
                                  onChange={() => checkboxes.toggle(company.id, plan.id)}
                                  checked={checkboxes.get(company.id, plan.id)}
                                  style={{color: "#1B1B1B"}}
                                />
                                <ItemContent onClick={() => {
                                  collapseAlertPlan(company.id, plan.id);
                                  // console.log(`Targets de ${plan.nome}:`, plan.alvos.map(target => target.nome));
                                }}>
                                  <Span ml='16px'>{plan.nome}</Span>
                                  <MdExpandMore style={{fontSize: '32px'}}/>
                                </ItemContent>
                              </PlanItem>
                            )}
                          >
                            {plan.alvos
                              .sort((a, b) => 
                                sortAscending ? a.nome.localeCompare(b.nome) : b.nome.localeCompare(a.nome)
                              )
                              .map((target) => (
                                inRainAlert.map(item => {
                                  if(item.id_alvo_id === target.id_alvo){
                                    // console.log(item);
                                    return(
                                      <Column key={`sidebarAlvoAlert-${plan.id}-${target.id}`}>
                                        <TargetInfo
                                          target={target}
                                          plan={plan}
                                          company={company}
                                          checkboxes={checkboxes}
                                          checked={true}
                                          alerts={true}
                                          alertType='InRain'
                                          interfaceTimer='' 
                                        />
                                      </Column>
                                    )
                                  }
                                })
                              ))
                            }
                          </Collapsible>
                        ))}
                    </Collapsible>
                )})}
              </Collapsible>

            </>
            )}
        </TargetList>
      </PlansTabStyled>
  );
};

OptionsTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired
};

PlansTab.propTypes = {
  setPlanTabWidth: PropTypes.func.isRequired,
};

export default PlansTab;
