import './style.css';
import Map from './components/Map';
import PropTypes from 'prop-types';
import Sidebar from './components/Sidebar';
import { ToolsContextProvider } from 'Context/ToolsContext';
import { MapContextProvider, useMapContext } from 'Context/MapContext';
import { SatelliteContextProvider } from 'Context/SatelliteContext';
import RightMapMenu from './components/RightMapMenu';
import {Icon} from '@material-ui/core';
import {OpenButton, ZoomsDiv, ZoomDiv, ZoomButton} from './styled';
import AlertsPanel from './components/AlertsPanel';
import ForecastPanel from './components/ForecastPanel';
import TargetContextProvider from 'Context/TargetContext';
import TargetStateContextProvider from 'Context/TargetStateContext';
import DischargesContextProvider from 'Context/DischargesContext';
import RadarContextProvider from 'Context/RadarContext';
import ClientControlPanelProvider from 'Context/ClientControlPanelContext';
import ControlPanelProvider from 'Context/ControlPanelContext';
import ModalContextProvider from 'Context/ModalContext';
import { useModal } from 'Context/ModalContext';
import useHotkeys from 'pages/MapsPortal/hotkeys/useHotkeys';
import {MdZoomOutMap} from 'react-icons/md';
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai';
import TopMenu from './components/TopMenu';
import SentNotifications from './components/SentNotifications';
import { useSelector } from 'react-redux';
import ClientTargetStateContext from 'Context/ClientTargetStateContext';
import useBound from 'hooks/useBound';
import TargetCustomAlertStateContextProvider from 'Context/TargetCustomAlertStateContext';

function Hotkeys() {
  useHotkeys()
  return <></>
}

function Modal(){
  const { modalType, open, tab} = useModal();
  const {hotkeys} = useMapContext();
  const dischargesHistory = useSelector(state => state.polygonProvider);

  return(
    <>
      {hotkeys && !open && !dischargesHistory && <Hotkeys />}
      {modalType === 'monitoring' && open && <AlertsPanel tab={tab} />}
      {modalType === 'prediction' && open && <ForecastPanel tab={tab} />}
    </>
  )
}

function MapsZoom () {
  const {resetClientMapPosition, mapRef} = useMapContext();
  const [maxBoundsState, centerCoordinate] = useBound();

  const mapZoomIn = () => {
    const map = mapRef.current;
    const zoom = map?.getZoom() + 1;
    map?.flyTo({
      zoom: zoom,
      essential: true
    })
  }
  const mapZoomOut = () => {
    const map = mapRef.current;
    const zoom = map?.getZoom() - 1;
    map?.flyTo({
      zoom: zoom,
      essential: true
    })
  }

  if(maxBoundsState && centerCoordinate)
  return (
    <ZoomsDiv >
      <ZoomButton onClick={resetClientMapPosition}>
        <MdZoomOutMap onClick={resetClientMapPosition}/>
      </ZoomButton>
      <ZoomDiv>
        <ZoomButton onClick={mapZoomIn}>
          <AiOutlinePlus />
        </ZoomButton>
        <ZoomButton onClick={mapZoomOut}>
          <AiOutlineMinus />
        </ZoomButton>
      </ZoomDiv>
    </ZoomsDiv>
  )
}


function MonitoringPage() {
  return (
          <TargetContextProvider>
              <ClientTargetStateContext>
                <TargetCustomAlertStateContextProvider>
                  <MapContextProvider> 
                    <ToolsContextProvider>
                      <DischargesContextProvider>
                        <RadarContextProvider>
                          <TargetStateContextProvider>
                            <SatelliteContextProvider>
                              <ControlPanelProvider>
                              <ClientControlPanelProvider>
                                <ModalContextProvider>
                                  <div className="MonitoringPage" >
                                    <Modal />
                                    <Sidebar />
                                    <MapsZoom />
                                    <div className="map">
                                        <Map  />
                                    </div>
                                    <TopMenu />
                                    <RightMapMenu />
                                  </div>
                                </ModalContextProvider>
                              </ClientControlPanelProvider>
                              </ControlPanelProvider>
                            </SatelliteContextProvider>   
                          </TargetStateContextProvider>
                        </RadarContextProvider>
                      </DischargesContextProvider>
                    </ToolsContextProvider>
                  </MapContextProvider>
                </TargetCustomAlertStateContextProvider>
              </ClientTargetStateContext>
          </TargetContextProvider>
    );
}
export default MonitoringPage;
