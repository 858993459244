/* Libraries */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
/* Material UI */
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField"
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { BiTrash } from "react-icons/bi";
/* Components */
import UploadButton from "../../../../components/UploadButton/index"
import { useToast } from 'Context/ToastContext';
import ModalInfo from "../../../../components/Modal/ModalInfo";
import {StyledTableMini, StyledHeadMini, StyledBodyMini, StyledCellMini, StyledIconCellMini, StyledSmallCellMini } from '../../../../containers/Pages/Table/Table-jss';
import classNames from 'classnames';
import { PapperBlock } from 'components';
/* CSS */
import styles from "../edit-jss";
import fontStyles from "styles/fonts-jss";
/* Services */
import { patchCompany } from "services/adminDashboard/company";
/* Images */
import file_present_icon from 'images/file_present_icon.png'
/* AWS */
import AWS from 'aws-sdk';
const S3_BUCKET = 'empresas/logos';
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_WEATHER_ACCESS_KEY_NEW,
  secretAccessKey: process.env.REACT_APP_AWS_WEATHER_SECRET_ACCESS_KEY_NEW,
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});


const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    toggleGroup: {
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.menu.white,
        backgroundColor: theme.palette.menu.mediumGrey,
      }
    }
  },
}));


const Company = (props) => {
  const { classes } = props;
  const fonts = fontStyles();
  const colors = useStyles();
  const location = useLocation();
  const history = useHistory();
  const companyData = location.state.companyData;
  const [reload, setReload] = useState();
  const [nome, setNome] = useState(companyData?.nome);
  const [razao_social, setRazao_social] = useState(companyData.razao_social? companyData.razao_social : "");
  const [comentarios, setComentarios] = useState(companyData.comentarios? companyData.comentarios : "");
  const [funcionamento, setFuncionamento] = useState(companyData.funcionamento.map((data) => JSON.parse(data)));
  const [datafunc_dias, setDatafunc_dias] = useState();
  const [datafunc_hora_inicio, setDatafunc_hora_inicio] = useState();
  const [datafunc_hora_fim, setDatafunc_hora_fim] = useState();
  const [nameErrors, setNameErrors] = useState();
  const [operationHoursErrors, setOperationHoursErrors] = useState();
  const [operationDaysErrors, setOperationDaysErrors] = useState();
  const [commentsErrors, setCommentsErrors] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [commentsCount, setCommentsCount] = useState(0);
  const [allDays, setAllDays] = useState(false);
  const [file, setFile] = useState(companyData.caminho_imagem? companyData.caminho_imagem : "");
  const [previewImage, setPreviewImage] = useState(file);
  const openToast = useToast();
  const moment = require('moment');
  const dispatch = useDispatch();
  const [disableButton, setDisableButton] = useState(false);
  const [disableUpdateButton, setDisableUpdateButton] = useState(false);
  var aux = 0;

  const toggleActive = () => {
    setAllDays(!allDays)
    if(allDays) {
      setDatafunc_dias([]);
    }
    else {
      setDatafunc_dias(
        ["Dom, ", "Seg, ", "Ter, ", "Qua, ", "Qui, ", "Sex, ", "Sab, "]
      );
    }
  }

  const uploadOnBucket = async (file) => {
    const params = {
      Key: `${nome}`,
      Body: file,
      ContentType: file.type,
      Bucket: S3_BUCKET,
    };

    try {
      await myBucket.putObject(params).promise();
      return `https://empresas.s3.amazonaws.com/logos/${nome}`
    } catch (err) {
      console.log(err)
      openToast(
        'error',
        'Problema ao enviar imagem! Entre na tela de edição novamente e tente um novo cadastro!',
      );
    } 
  };

  const removeFileFromBucket = async (fileUrl) => {
    const fileName = fileUrl.split("/").pop(); 
    const params = {
      Key: fileName,
      Bucket: S3_BUCKET,
    };
  
    try {
      await myBucket.deleteObject(params).promise();
      openToast("success", "Logo removida do banco de dados com sucesso!");
      setPreviewImage(null);
      setFile(null);
    } catch (err) {
      console.log(err)
      openToast("error", "Não foi possível remover a logo do banco de dados, tente novamente.");
    }
  };

  const handleUpdate = async () => {
    if(!disableUpdateButton){
      setDisableUpdateButton(true);
      if (nameErrors || commentsErrors || funcionamento.length === 0 || operationDaysErrors || operationHoursErrors) {
        openToast("warning", "Um ou mais campos possuem erros, verifique antes de continuar!")
      }
      else {
        var aux = [];

        for(let i = 0; i < funcionamento.length; i++) {
          aux.push([JSON.stringify(funcionamento[i])]);
        }

       const data = {
          nome: nome.trim(),
          razao_social: razao_social != "" ? razao_social.trim() : null,
          comentarios: comentarios != "" ? comentarios.trim() : null,
          funcionamento: aux,
          caminho_imagem:file != "" ? file : "",
       }

       if(file){
        try {
          const logoUrl = await uploadOnBucket(file);  
          data.caminho_imagem = logoUrl;  
        } catch (error) {
          openToast("Erro ao fazer envio de logo, tente novamente ");
          setDisableButton(false);
          return;
        }
      } else {
        data.caminho_imagem = null;   
       }

       await patchCompany(companyData.id_empresa, data).then((response) => {
          if(response.status === 200) {
            openToast("success", "Alteração realizada com sucesso!");
            setDisableUpdateButton(false);
             history.push({
                 pathname: `/administracao/empresas/${companyData.id_empresa}`,
                 state: { data: data },
             });
          }
          else{
            openToast("error", "Problema ao salvar alterações!");
            setDisableUpdateButton(false);       
          }
       }).catch((err) => {
        openToast("error", "Problema ao salvar alterações!");
        setDisableUpdateButton(false);
       });
      }
    }
  }

  const setDays = (data) => {
    if (data && data.length > 0) {
      var days = "";
      var seg, ter, qua, qui, sex, sab, dom = false;

      for (let i = 0; i < data.length; i++) {
        if (data.includes("Seg, ") && !seg) {
          days = "Seg, ";
          seg = true;
        }
        else if (data.includes("Ter, ") && !ter) {
          days ===  ""? days = "Ter, " : days = days + "Ter, ";
          ter = true;
        }
        else if (data.includes("Qua, ") && !qua) {
          days ===  ""? days = "Qua, " : days = days + "Qua, ";
          qua = true;
        }
        else if (data.includes("Qui, ") && !qui) {
          days ===  ""? days = "Qui, " : days = days + "Qui, ";
          qui = true;
        }
        else if (data.includes("Sex, ") && !sex) {
          days ===  ""? days = "Sex, " : days = days + "Sex, ";
          sex = true;
        }
        else if (data.includes("Sab, ") && !sab) {
          days ===  ""? days = "Sab, " : days = days + "Sab, ";
          sab = true;
        }
        else if (data.includes("Dom, ") && !dom) {
          days ===  ""? days = "Dom, ": days = days + "Dom, ";
          dom = true;
        }}
        
        return days.slice(0, days.length - 2);
      }
  }

  const handleOperationDays = (event, days) => {
    setDatafunc_dias(days);
  };

  const handleDelete = (datafunc_dias, datafunc_hora_inicio, datafunc_hora_fim) => {
    var temp = [];
    var temp2 = [];
    aux = aux + 1
    temp = funcionamento.findIndex(data => data.datafunc_dias == datafunc_dias && data.datafunc_hora_inicio == datafunc_hora_inicio && data.datafunc_hora_fim == datafunc_hora_fim)
    temp2 = funcionamento.filter((dados, index) => index != temp)
    setFuncionamento(temp2);
    setReload(aux);
    openToast("success", "Período deletado com sucesso!")
  };

  const handleChangeAdd = (datafunc_dias, datafunc_hora_inicio, datafunc_hora_fim) => {
    if (operationHoursErrors || datafunc_dias?.length === 0 || !datafunc_hora_inicio || !datafunc_hora_fim) {
      openToast("warning", "Um ou mais campos possuem erros, verifique antes de continuar!")
    }    
    else {
      if(!disableButton && !disableButton){
        setDisableButton(true);
        var temp1 = [{datafunc_dias, datafunc_hora_inicio, datafunc_hora_fim}];
        var temp2 = funcionamento;

        if(temp2 === null) {
          temp2 = temp1
        }
        else {
          temp1 = temp1.concat(temp2);
          temp2 = temp1;
          var p = [];

          for (let i = 0; i < temp2.length; i++) {
            if (temp2[i].datafunc_dias.includes("Seg, ")) {
              p.push({priority: 1, index: i});
            }
            else if (temp2[i].datafunc_dias.includes("Ter, ")) {
              p.push({priority: 2, index: i});  
            }
            else if (temp2[i].datafunc_dias.includes("Qua, ")) {
              p.push({priority: 3, index: i});
            }
            else if (temp2[i].datafunc_dias.includes("Qui, ")) {
              p.push({priority: 4, index: i});
            }
            else if (temp2[i].datafunc_dias.includes("Sex, ")) {
              p.push({priority: 5, index: i});
            }
            else if (temp2[i].datafunc_dias.includes("Sab, ")) {
              p.push({priority: 6, index: i});
            }
            else if (temp2[i].datafunc_dias.includes("Dom, ")) {
              p.push({priority: 7, index: i});
            }}
        }

        p = p.sort((a,b) => a.priority - b.priority);
        let aux = [];

        for(let i = 0; i < p.length; i++) {
          aux.push(temp2[p[i].index]);
        }

        setFuncionamento(aux);
        setDatafunc_dias([]);
        setDatafunc_hora_inicio("");
        setDatafunc_hora_fim("");
        setAllDays(false);
      } 
    }
    setDisableButton(false);
  };

  const handleSetFileUpload = (files) => {
    setFile(files);
    const previewFile = files
    const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result); 
      };
      reader.readAsDataURL(previewFile); 
    aux = aux + 1;
    setReload(aux);
  };

  const removeFileUploaded =async () => {
    if (file === companyData.caminho_imagem) {
      await removeFileFromBucket(file); 
    }
    setFile(null);
    setPreviewImage(null); 
    aux = aux + 1;
    setReload(aux);
  };

  useEffect(() => {
    if(!nome) {
      setNameErrors("Campo obrigatório!");
    }
    else{
      setNameErrors("");
    }

    if(datafunc_hora_inicio && datafunc_hora_fim) {
      if (datafunc_hora_inicio != moment(datafunc_hora_inicio, "HH:mm").format("HH:mm"))
      {
        setOperationHoursErrors("A hora deve estar no formato correto");
      }
      else if (datafunc_hora_fim != moment(datafunc_hora_fim, "HH:mm").format("HH:mm"))
      {
        setOperationHoursErrors("A hora deve estar no formato correto");
      }
      else if (moment(datafunc_hora_inicio, "HH:mm").isAfter(moment(datafunc_hora_fim, "HH:mm")))
      {
        setOperationHoursErrors("A hora inicial não pode ser posterior a hora final!");
      }
      else if (moment(datafunc_hora_fim, "HH:mm").isBefore(moment(datafunc_hora_inicio, "HH:mm")))
      {
        setOperationHoursErrors("A hora final não pode ser anterior a hora final!");
      }
      else if (moment(datafunc_hora_fim, "HH:mm").isSame(moment(datafunc_hora_inicio, "HH:mm")))
      {
        setOperationHoursErrors("A hora inicial e final não podem ser as mesmas!");
      }
      else {
        setOperationHoursErrors("");
      }
    }
    else{
      if(funcionamento.length === 0){
        setOperationHoursErrors("Campo obrigatório!");
      }
    }

    if(datafunc_dias?.length === 0 && funcionamento?.length === 0) {
      setOperationDaysErrors("Campo obrigatório!");
    }
    else{
      setOperationDaysErrors("");
    }

    if (comentarios && comentarios.length) {
      setCommentsCount(comentarios.length)
    }
    if (comentarios && comentarios.length > 0) {
      if (comentarios[0] === " ") {
        setCommentsErrors("O campo não deve conter espaços em branco no começo/final!");
      }
      else if (comentarios[comentarios.length - 1] === " ") {
        setCommentsErrors("O campo não deve conter espaços em branco no começo/final!");
      }
      else {
        setCommentsErrors("");
      }
    }
    else{
      setCommentsErrors("");
    }

    if(errorMessage){
      openToast("warning", errorMessage);
      setFile(null);
      setPreviewImage(null);
      aux = aux + 1;
      setReload(aux);
      setErrorMessage("");
    }
  }, [nome, razao_social, comentarios, funcionamento, datafunc_dias, datafunc_hora_inicio, datafunc_hora_fim, reload, errorMessage])


  return (
    <PapperBlock title={"Editar empresa"} desc={companyData.nome} icon="ion-ios-list-box-outline">
      <div>
        <div className={classes.registerContainer}>
          <Typography  variant="subtitle1" color="primary" style={fonts.formsSubTitle}>
            Dados da empresa
          </Typography>
          <div className={classes.registerContentContainer}>
            <div className={classes.registerItemContainer}>
              <TextField
                id="name"
                label="Nome"
                className={classNames(classes.textFieldDefault, colors.textField)}
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                margin="normal"
                shrink={true}
                placeholder="Nome"
                variant="outlined"
                inputProps={{style: fonts.formsField, maxLength: 50}}
                InputLabelProps={{style: fonts.formsLabel}} />

                {nameErrors && <p style={fonts.formsErrorMessage}>{nameErrors}</p>}
              </div>

              <div className={classes.registerItemContainer}>
                <TextField
                  id="corporate-name"
                  label="Razão Social"
                  className={classNames(classes.textFieldDefault, colors.textField)}
                  value={razao_social}
                  onChange={(e) => setRazao_social(e.target.value)}
                  margin="normal"
                  placeholder="Razão Social"
                  variant="outlined"
                  inputProps={{style: fonts.formsField, maxLength: 50}}
                  InputLabelProps={{style: fonts.formsLabel}} />
              </div>
              
              <div className={classes.registerItemContainer} style={{paddingTop: "15px"}}>
                <UploadButton onChange={handleSetFileUpload} fileType={"image"} setErrorMessage={setErrorMessage} buttonName={"Anexar logo"} />
              </div>
              
              {file &&
                <div className={classes.registerItemContainer}  >
                  <div style={{paddingTop: "15px"}} className={classes.fileRemoveContainer} onClick={() => removeFileUploaded()}>
                    <BiTrash style={{width:"20px", height:"20px"}} />
                  </div>
                  <img
                    src={previewImage}
                    alt="Pré-visualização"
                    style={{ maxWidth: "350px", height: "80px", objectFit: "cover", marginTop: "10px" }}
                  />
                </div>
              }

          </div>
        </div>

        <div className={classes.registerContainer}>
          <div className={classes.registerContentContainer}>
            <div className={classes.registerItemContainer} style={{width: "100%"}}>
              <TextField
                id="comments"
                label="Observações"
                multiline
                rows={5}
                value={comentarios}
                onChange={(e) => setComentarios(e.target.value)}
                className={classes.textFieldComments}
                margin="normal"
                variant="outlined"
                inputProps={{style: fonts.formsField, maxLength: 150}}
                InputLabelProps={{style: fonts.formsLabel}}
                InputProps={{
                  endAdornment:
                    <span className={classes.endAdornment} style={{marginTop: "5%"}}>{commentsCount + "/150"}</span>
                }}
              />
            {commentsErrors && <p style={fonts.formsErrorMessage}>{commentsErrors}</p>}
            </div>
          </div>
        </div>

        <div className={classes.registerContainer}>
          <div className={classes.registerContentContainer}>
            <Typography  variant="subtitle1" color="primary" style={fonts.formsSubTitle}>
              Funcionamento da empresa
            </Typography>

            <ModalInfo
              linha1 = {"Para adicionar o período de funcionamento basta selecionar os dias, o horario de funcionamento nesses dias e clicar em adionar!"}
              linha2 = {"Após adicionar um período, você poderá adicionar outros caso precise!"}
              marginLeft = {1}
              height = {125}
              width = {245}
              marginTop = {161}
              top = {111}
              divMgLf={0}
              divMgBt={23}
              divMgTp={0} />
          </div>

          <div className={classes.registerContentContainer}>
            <Switch
              checked={allDays}
              onChange={toggleActive}
              value={allDays}
              size="small"
              className={classes.switch}
              color="primary" />

            <Typography  variant="subtitle1" color="primary" style={fonts.formsSubTitle}>
              Selecionar tudo
            </Typography> 
          </div>

          <div className={classes.registerContentContainer}>
            <div className={classes.registerItemContainer}>
              <ToggleButtonGroup
                value={datafunc_dias}
                onChange={handleOperationDays}
                color="primary"
                size="large"
              >
                <ToggleButton value="Dom, " style={fonts.toggleGroup}>Dom</ToggleButton>
                <ToggleButton value="Seg, " style={fonts.toggleGroup}>Seg</ToggleButton>
                <ToggleButton value="Ter, " style={fonts.toggleGroup}>Ter</ToggleButton>
                <ToggleButton value="Qua, " style={fonts.toggleGroup}>Qua</ToggleButton>
                <ToggleButton value="Qui, " style={fonts.toggleGroup}>Qui</ToggleButton>
                <ToggleButton value="Sex, " style={fonts.toggleGroup}>Sex</ToggleButton>
                <ToggleButton value="Sab, " style={fonts.toggleGroup}>Sab</ToggleButton>
              </ToggleButtonGroup>

              {operationDaysErrors && <p style={fonts.formsErrorMessage}>{operationDaysErrors}</p>}
            </div>

            <div className={classes.registerIteminitialconsoContainer}>
              <div className={classes.registerContentContainer}>
                <div className={classes.dateHourContainer} style={{marginTop: "11px"}}>
                  <InputMask
                    mask="99:99"
                    value={datafunc_hora_inicio}
                    onChange={(e) => setDatafunc_hora_inicio(e.target.value)}
                    disabled={false}
                    maskChar=" "
                  >{() =>
                    <TextField
                      id="Initial-time"
                      className={classes.selectFieldSmall}
                      margin="normal"
                      variant="outlined"
                      label="Inicio"
                      inputProps={{style: fonts.formsField}}
                      InputLabelProps={{style: fonts.formsLabel, shrink: true}}
                    />}
                  </InputMask>
                                    
                  <InputMask
                    mask="99:99"
                    value={datafunc_hora_fim}
                    onChange={(e) => setDatafunc_hora_fim(e.target.value)}
                    disabled={false}
                    maskChar=" "
                  >{() =>
                    <TextField
                      id="Final-time"
                      className={classes.selectFieldSmall}
                      margin="normal"
                      variant="outlined"
                      label="Fim"
                      inputProps={{style: fonts.formsField}}
                      InputLabelProps={{style: fonts.formsLabel, shrink: true}}
                    />}
                  </InputMask>
                  
                </div>
              </div>
              {operationHoursErrors && <p style={fonts.formsErrorMessage}>{operationHoursErrors}</p>}
            </div>
            
            {datafunc_dias && datafunc_hora_inicio && datafunc_hora_fim && !operationHoursErrors &&
              <div className={classes.registerItemContainer}>
                <div className={disableButton? classes.disabledOutlineButton : classes.outlineButton} onClick={() => handleChangeAdd(datafunc_dias, datafunc_hora_inicio, datafunc_hora_fim)}>
                  <span style={fonts.buttonDefault} className={classes.outlineButtonText}>Adicionar</span> 
                </div>
              </div>
            }
          </div>
        </div>

        {funcionamento != null && funcionamento.length > 0 &&
          <div className={classes.registerContainer}>
            <div className={classes.registerContentContainer}>
              <StyledTableMini>
              <colgroup>
                {<col style={{width: "30%"}}></col>}
                {<col style={{width: "30%"}}></col>}
                {<col style={{width: "30%"}}></col>}
                {<col style={{width: "10%"}}></col>}
              </colgroup> 
                <StyledHeadMini >
                  <TableRow >
                    <StyledCellMini padding="normal" style={fonts.filterMenu}>Dias da semana</StyledCellMini>
                    <StyledSmallCellMini align="left" style={fonts.filterMenu}>Horário inicial</StyledSmallCellMini>
                    <StyledSmallCellMini align="left" style={fonts.filterMenu}>Horário final</StyledSmallCellMini>
                    <StyledIconCellMini align="left"></StyledIconCellMini>
                  </TableRow>
                </StyledHeadMini>
                <StyledBodyMini>
                  {funcionamento.map((data, index) => ([
                    <TableRow key={index}>
                      <StyledCellMini padding="normal" style={fonts.cellContent}>{setDays(data.datafunc_dias)}</StyledCellMini>
                      <StyledSmallCellMini align="left" style={fonts.cellContent}>{data.datafunc_hora_inicio}</StyledSmallCellMini>
                      <StyledSmallCellMini align="left" style={fonts.cellContent}>{data.datafunc_hora_fim}</StyledSmallCellMini>
                      <StyledIconCellMini align="left">
                        <div className={classes.tableIcon} onClick={() => handleDelete(data.datafunc_dias, data.datafunc_hora_inicio, data.datafunc_hora_fim)}>
                          <BiTrash style={{width:"20px", height:"20px"}} />
                        </div>
                      </StyledIconCellMini>
                    </TableRow>
                  ]))}
                </StyledBodyMini>
              </StyledTableMini>
            </div>
          </div>        
        } 

        <div className={classes.registerButtonContainer}>
          <div>
            <div className={disableUpdateButton? classes.disabledOutlineButton : classes.outlineButton} onClick={handleUpdate}>
              <span style={fonts.buttonDefault} className={classes.outlineButtonText}>Salvar alterações</span> 
            </div>
          </div>
        </div>
      </div>
    </PapperBlock>
  )
}

Company.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Company);